import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Paragraph from "../../../../../components/paragraph"
import Note from "../../../../../components/note"
import ValidatableInput from "../../../../../content/kurse/hoelderlins-reisen/02-reiseerinnerung/validatable-input"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/02-reiseerinnerung/silben" />
      }
      navigation={navigation}
    >
      <Seo title="Wortneuschöpfungen" />
      <Stack>
        <Heading as="h2" level={2}>
          Wortneuschöpfungen
        </Heading>
        <Paragraph>
          »Vaterlandsstädte« und »Ländlichschönste« sind Wörter, die Hölderlin
          selbst erfunden hat. Man findet sie in keinem Wörterbuch. Hölderlin
          liebte solche neuen Wortkombinationen. Man nennt sie »Neologismen«
          oder »Wortneuschöpfungen«.
        </Paragraph>
        <TaskSidebar
          main={
            <Stack>
              <Paragraph>
                Aus welchen Wörtern sind diese Neologismen zusammengesetzt?
              </Paragraph>
              <Stack space={6}>
                <ValidatableInput
                  id="q-01"
                  value="Vaterlandsstädte"
                  label="Vaterlandsstädte"
                  solution="Vater lands städte"
                />
                <ValidatableInput
                  id="q-02"
                  label="Ländlichschönste"
                  solution="Ländlich schönste"
                  value="Ländlichschönste"
                />
              </Stack>
            </Stack>
          }
          sidebar={
            <Note variant="task">
              Klicke in die Textfelder und setze ein Leerzeichen zwischen die
              einzelnen Wörter.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
